// import BootomBannerModule from './components/BootomBanner';
import HeadingBannerModule from './components/HeadingBanner';
import MiddleBannerModule from './components/MiddleBanner';
import { useSession } from 'next-auth/react';
import useTranslation from 'next-translate/useTranslation';
import router from 'next/router';
import BannerGames from './components/banner_games';
import BannerWinnerPrizes from './components/banner_winner_prizes';
import WinnerPrizesModal from '@components/WinnerPrizesModal';
import { useMediaQuery } from '@chakra-ui/react';

export default function AnylovyModule() {
  const { t } = useTranslation('landing-page');
  const { data: _session, status } = useSession();
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <HeadingBannerModule
        urlImage={'/imgs/anylovy/banner.png'}
        textTitle={
          isMobile ? t('banner-header-title-mobile') : t('banner-header-title')
        }
        textDescription={t('banner-header-description')}
        textButton={t('banner-button')}
        onClick={() => {
          router.push('/login');
        }}
        showButton={status === 'unauthenticated'}
        textLoadingButton={t('banner-button-loading')}
      />
      <MiddleBannerModule
        urlImageMobile={'/imgs/anylovy/mobilemoto.png'}
        urlImage={'/imgs/anylovy/moto.png'}
        textTitleLeft={t('banner-prizes-title-left')}
        textTitleRight={t('banner-prizes-title-right')}
        textTitle={t('banner-prizes-title')}
        textTitleMobile={t('banner-prizes-title-mobile')}
      />
      <BannerGames />
      <WinnerPrizesModal ChildComponet={BannerWinnerPrizes} />
    </>
  );
}
