
export enum GameFases {
  StartGame=0,
  Interaction,
  InteractionExplanation,
  SuccessFeedBack,
  FailFeedBack,
  Loading
}


interface GameCardStyle {
  icon: string;
  icon_url: string;
  bg_color: string;
  font_color: string;
  action_button_bg_color: string;
  action_button_font_color: string;
}

interface GameUserScore {
  type: 'coupons' | 'points';
  value: number;
}

export interface Game {
  idPromoFase: number;
  idGame: number;
  idStage: number;

  // "1" = "bolao" (que aparece embaixo) |
  // "2" = "bolao" (que aparece emcima junto com outros games) |
  // ...
  type: number;

  name: string;
  description: string;

  card_style: GameCardStyle;

  status: GameStatus; // 0-liberado, 1-jogado, 2-bloqueado
  user_score: GameUserScore[];


  start_date: string;
  end_date: string;

  results?: GameResults[];

  matches?: Match[];

  // coupons: number;
  // action?: {
  //   name: string;
  //   fn: () => void;
  // };
  // rememberMe: boolean;
  // isBlocked: boolean;
}

export interface Match {
  idMatch: number;
  name: string;
  place: string;
  matchStart: string;
  status: number;
  teams: Team[];
  score: GameUserScore[];
}

export interface Team {
  id: number;
  name: string;
  flag: string;
  guess?: number | null;
  result?: number | null;
}

/* ATILA 11/07/22 - Skype PROMO HUB + ATILA
 - 0 = aberto para jogar, existem perguntas pendentes ("jogar agora")
 - 1 = aguarda liberação da próxima rodada ("você já jogou hoje / volte amanhã")
 - 2 = bloqueado, pois o game não foi lançado ainda para a fase atual ("avise-me quando lançar")
 - 3 = bloqueado, pois o game não foi lançado ainda para a fase atual ("avise-me quando lançar")
 - 4 = finalizado. já jogou o jogo inteiro. ("este game expirou nesta fase")
 */
export enum GameStatus {
  Unlocked = 0,
  Played,
  Locked,
  WarnMe,
  Finished
}


// Bolão	1
// Palavra Premiada	3
// Live Premiada	4
// Missão Premiada	5
// Quiz Libertadores	6

export enum GameDetailType {
  PredictScore = 1,
  Keyword = 3,
  Live = 4,
  Mission = 5,
  Quiz = 6,
  Roulette = 10,
  ScratchCard = 7,
  GoalKick = 666,
  SevenErrors = 12
}

export interface GameQuestionDetail {
  right: number;
  wrong: number;
  qtd_day: number;
  answered: number;
  total: number;
  pending_day: number;
}

export enum GameScoreType {
  Cupouns = 'cupouns',
  Points = 'points',
  LucknumberWon = 'lucknumber_won',
  LucknumberTotal = 'lucknumber_total'
}

export interface GameScore {
  type: GameScoreType;
  value: number;
}

export interface GameDetail {
  idStage: number;
  idGame: number;
  stage_name: string;
  type: number;
  description: string;
  status: GameStatus;
  dt_end: string;
  dt_start: string;
  questions: GameQuestionDetail;
  score: GameScore[];
}

/*
{
  "id_game": "qHA967DOTKLrs7z+JHOg6OX7mJRxEUYWI27oynr/5v0=",
  "type": 1, // tipo do jogo, a ser definido, ex: 0-bolão, 1-quiz
  "description": "Aqui no quiz....",
  "status": 0, // 0-liberado, 1-jogado, 2-bloqueado
  "questions": {
    "right": 5,
    "wrong": 1,
    "total": 10 // peding = total - (right + wrong)
  },
  "score": [
    {
      "type": "cupouns",
      "value": 238
    },
    {
      "type": "points",
      "value": 50
    }
  ]
}
*/

export interface GameQuestionAnswer {
  idAnswer: number;
  text: string;
  value: number;
}

export interface GameQuestion {
  idGame: number;
  idFase: number;
  idQuestion: number;
  text: string;
  has_next: boolean;
  answers: GameQuestionAnswer[];
}

export interface GameAnswerFeedback {
  timestamp: string;
  status: number;
  message: string;
  lucknumber_won?:number;
  idCorrectAnswer: number;
}

/*
{
  "id_game": "",
  "id_promo_fase": "",
  "id_question": "",
  "text": "Quem descobriu o Brasil?",
  "answers": [
    {
      "id_answer": "",
      "text": "Blá",
      "value": 0
    },
    {
      "id_answer": "",
      "text": "Teste",
      "value": 1
    }
  ],
  "has_next": true // flag indicando se tem mais pergunta disponível
}
*/
export interface GameResults {
  question: string;
  answer: string;
  is_correct: boolean;
}
