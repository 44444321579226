import {
  Box,
  Flex,
  Image,
  Text,
  VStack,
  Wrap,
  WrapItem,
} from '@chakra-ui/react';
import { WinnerPrize } from '@interfaces/Winners';

export default BannerWinnerPrizes;

type WinnerPrizesModalProps = {
  winners: WinnerPrize[];
  onSelected: (winner: WinnerPrize) => void;
};

function BannerWinnerPrizes({ winners, onSelected }: WinnerPrizesModalProps) {
  return (
    <Flex
      backgroundColor={'#ffefeb'}
      w="full"
      flexDir="column"
      pb={{
        base: 0,
        sm: 0, // 480px
        md: 10, // 768px
        lg: 10, // 992px
        xl: 10, // 1280px
        '2xl': 10, // 1536px
      }}
    >
      <Text
        align={'center'}
        fontSize={{
          base: '25px',
          sm: '30px', // 480px
          md: '30px', // 768px
          lg: '40px', // 992px
          xl: '45px', // 1280px
          '2xl': '45px', // 1536px
        }}
        fontWeight={'700'}
        fontFamily="Montserrat"
        color={'#7F232A'}
      >
        Ganhadoras da Promoção
      </Text>
      <Text
        fontSize={['15px', '17px', '19px', '22px']}
        align="center"
        color="#7F232A"
        fontFamily={'Raleway'}
        fontWeight={400}
      >
        {winners != undefined && winners.length > 0
          ? 'Selecione o prêmio para ver as ganhadoras:'
          : 'Ainda não há ganhadores.'}
      </Text>
      <Wrap
        spacingX={{
          base: '35px',
          sm: '35px', // 480px
          md: '25px', // 768px
          lg: '35px', // 992px
          xl: '0px', // 1280px
          '2xl': '35px', // 1536px
        }}
        justify={{
          base: 'center',
          sm: 'space-evenly', // 480px
          md: 'space-evenly', // 768px
          lg: 'space-evenly', // 992px
          xl: 'center', // 1280px
          '2xl': 'center', // 1536px
        }}
        px={{
          base: '0px',
          sm: '0px', // 480px
          md: '0px', // 768px
          lg: '0px', // 992px
          xl: '46px', // 1280px
          '2xl': '64', // 1536px
        }}
      >
        {winners?.map((winner, index) => (
          <WrapItem p={4} cursor={'pointer'} key={`wrap-item-${index}`}>
            <VStack onClick={() => onSelected(winner)}>
              <Box
              // w={{
              //   base: '320px',
              //   sm: '180px', // 480px
              //   md: '180px', // 768px
              //   lg: '200px', // 992px
              //   xl: '280px', // 1280px
              //   '2xl': '280px', // 1536px
              // }}
              >
                {winner.description && (
                  <Image
                    src={winner.description}
                    alt={winner.title}
                    width={'331px'}
                    height={'280px'}
                  />
                )}
              </Box>
            </VStack>
          </WrapItem>
        ))}
      </Wrap>
    </Flex>
  );
}
