import {
  Flex,
  HStack,
  Heading,
  Stack,
  Text,
  VStack,
  useMediaQuery,
  useToast,
} from '@chakra-ui/react';
import useTranslation from 'next-translate/useTranslation';
import CustomNextImage from '@components/ChakraNextImage';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useStages } from '@hooks/useStages';
import { useEffect } from 'react';
import logger from '@logger/logger';
import { GameStatus } from '@interfaces/Game';
import { isBefore } from 'date-fns';

export default function BannerGames() {
  const { t } = useTranslation('landing-page');
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const toast = useToast();
  const router = useRouter();
  const { status } = useSession();
  const { stages, loadNewStages } = useStages();

  function loadStages() {
    logger.info('loadStates called');
    loadNewStages().then(() => {
      // setLoading(false);
    });
  }
  useEffect(() => {
    if (status !== 'loading' && !stages) {
      // setLoading(true);
      loadStages();
    }
  }, [status, stages]);

  const handleDailyGame = () => {
    if (stages == undefined) {
      toast({
        title: 'Aviso',
        description:
          'Não foi possível carregar o game. Tente novamente em instantes.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    const dayGame = stages[0].games.find(
      (game) => !game.name.includes("Final") && game.status == GameStatus.Unlocked
    );
    if (dayGame == undefined) {
      toast({
        title: 'Game indisponível',
        description:
          'Você já concluiu este game. Faça uma compra para acessar o game do Prêmio Final!',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    router.push(
      `/game?id_game=${dayGame.idGame}&id_stage=${dayGame.idStage}&type=${dayGame.type}`
    );
  };

  const handleFinalGame = () => {
    if (stages == undefined) {
      toast({
        title: 'Aviso',
        description:
          'Não foi possível carregar o game. Tente novamente em instantes.',
        status: 'error',
        duration: 9000,
        isClosable: true,
      });
      return;
    }

    const finalGame = stages[0].games.find((game) => game.name.includes("Final"));
    if (finalGame == undefined) {
      toast({
        title: 'Aviso',
        description:
          'Problemas ao encontrar jogo final, tente novamente mais tarde',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    }
    if (
      finalGame.status == GameStatus.Played ||
      finalGame.status == GameStatus.Finished
    ) {
      toast({
        title: 'Aviso!',
        //você ja subiu o cupom fiscal e ja jogou. boa sorte.
        description:
          'O cupom fiscal já foi disponibilizado e o jogo já foi realizado. Boa Sorte!',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    } else if (finalGame.status == GameStatus.WarnMe) {
      toast({
        title: 'Aviso!',
        description: 'Jogo ainda não disponivel, aguarde para mais novidades!',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    } else if (
      isBefore(
        new Date((finalGame.end_date ?? '').replaceAll('.000Z', '')),
        new Date()
      )
    ) {
      toast({
        title: 'O jogo expirou.',
        description:
          'Não é possível jogar, aguarde novidades para jogar novamente!',
        status: 'warning',
        duration: 9000,
        isClosable: true,
      });
      return;
    } else if (finalGame.status == GameStatus.Unlocked) {
      router.push(
        `/game?id_game=${finalGame.idGame}&id_stage=${finalGame.idStage}&type=${finalGame.type}`
      );
    } else {
      router.push(
        `/game/check?id_game=${finalGame.idGame}&id_stage=${finalGame.idStage}&type=${finalGame.type}`
      );
    }
  };

  const CardGame = ({
    title,
    id,
    onClick,
    description,
    color,
  }: {
    title: string;
    id: string;
    color: string;
    onClick: () => void;
    description: any;
  }) => {
    return (
      <VStack
        id={id}
        bgColor={color}
        borderRadius="40px"
        width={{
          sm: '350px', // 480px
          md: '350px', // 768px
          lg: '400px', // 992px
          xl: '566px', // 1280px
          '2xl': '566px', // 1536px
        }}
        height="257px"
        alignItems="center"
        justifyContent="start"
        textAlign="center"
        shadow={'lg'}
        cursor={'pointer'}
        onClick={onClick}
        color={'white'}
        fontFamily="Raleway"
        py={2}
      >
        <HStack
          fontSize={{
            base: '34px',
            sm: '26px', // 480px
            md: '26px', // 768px
            lg: '34px', // 992px
            xl: '39px', // 1280px
            '2xl': '39px', // 1536px
          }}
          fontWeight={700}
        >
          <CustomNextImage
            src={'/imgs/anylovy/Icon-Question.png'}
            alt="?"
            width={'83px'}
            height={'55px'}
          />
          <Text>{title}</Text>
          <CustomNextImage
            src={'/imgs/anylovy/Icon-Question.png'}
            alt="?"
            width={'83px'}
            height={'55px'}
          />
        </HStack>
        <HStack
          width={'100%'}
          bgColor={'#FFF8F6'}
          align={'center'}
          textAlign={'center'}
          justify={'center'}
          fontWeight="bold"
        >
          <Text color={color}>JOGAR AGORA!</Text>
        </HStack>

        <HStack
          p={2}
          fontSize={{
            base: '20px',
            sm: '20px', // 480px
            md: '20px', // 768px
            lg: '25px', // 992px
            xl: '25px', // 1280px
            '2xl': '25px', // 1536px
          }}
        >
          {description}
        </HStack>
      </VStack>
    );
  };

  return (
    <VStack justifyContent="center" p="2%" bgColor={'#FFF8F6'}>
      <Heading mb={5} variant="lpBannerTitle">
        {t('banner-games-title')}
      </Heading>
      <Stack
        spacing={{
          base: 10,
          sm: 2, // 480px
          md: 5, // 768px
          lg: 10, // 992px
          xl: 10, // 1280px
          '2xl': 10, // 1536px
        }}
        direction={!isMobile ? 'row' : 'column'}
        align="center"
      >
        <CardGame
          id="game-banner-day"
          onClick={handleDailyGame}
          color="#DC001F"
          description={
            <Text>
              É só se cadastrar pra concorrer a vários prêmios <u> de graça</u>!
            </Text>
          }
          title="GAME DO DIA"
        />
        <Flex
          width={{
            base: '66px',
            sm: '66px', // 480px
            md: '55px', // 768px
            lg: '77px', // 992px
            xl: '77px', // 1280px
            '2xl': '77px', // 1536px
          }}
          height={{
            base: '66px',
            sm: '66px', // 480px
            md: '55px', // 768px
            lg: '77px', // 992px
            xl: '77px', // 1280px
            '2xl': '77px', // 1536px
          }}
        >
          <CustomNextImage
            src={'/imgs/anylovy/e.png'}
            alt="E"
            width={!isMobile ? '77px' : '66px'}
            height={!isMobile ? '88px' : '77px'}
          />
        </Flex>
        <CardGame
          id="game-banner-final"
          onClick={handleFinalGame}
          color="#FF447C"
          description={
            <Text>
              A cada R$ 50 em compras no nosso estande, você pode concorrer à
              Honda Elite 125!
            </Text>
          }
          title="GAME FINAL"
        />
      </Stack>
    </VStack>
  );
}
