import {
  Container,
  Box,
  VStack,
  Text,
  Link,
  Stack,
  StackDivider,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';

export default function Footer({ minH }: { minH?: string }) {
  const router = useRouter();
  const { t } = useTranslation('landing-page');
  return (
    <Box
      pt={[5, 6, 9, 12]}
      bg={'lpFooterColor'}
      as={motion.div}
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition="1s linear"
      viewport={{ once: true }}
      position={'relative'}
      // zIndex={'overlay'}
      bottom={0}
      minH={minH}
      w={'full'}
    >
      <Container zIndex={0} maxW={{ base: 'full', md: '5xl', xl: '1690px' }}>
        <VStack zIndex={0} spacing={5}>
          <Stack
            direction={['column', 'column', 'row']}
            spacing="24px"
            align="center"
          >
            <Link
              variant="lpFooterLinks"
              onClick={() => {
                router.push('/faq');
              }}
            >
              {t('banner-footer-duvidas')}
            </Link>
            <StackDivider
              display={{ base: 'none', md: 'flex' }}
              alignSelf={'center'}
              w="4px"
              h="26px"
              bgColor="#7F232A"
            />
            <Link
              variant="lpFooterLinks"
              onClick={() => {
                router.push('/terms');
              }}
            >
              {t('banner-footer-politica')}
            </Link>
          </Stack>
          <Text variant="lpFooterText">
            Participações válidas durante o intervalo de horário das 12h às 19h
            durante os dias 09.09.2022 à 12.09.23 na feira Beauty Fair. Consulte
            formas de participação e Regulamentos completos no site{' '}
            <a
              href="https://promo.anylovy.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              https://promo.anylovy.com.br/
            </a>
            . Imagens ilustrativas. Certificados de Autorização SEAE/ME CERTIFICADO DE AUTORIZAÇÃO SRE/ME nº 03.029328/2023
            e CERTIFICADO DE AUTORIZAÇÃO SRE/ME nº 03.029328/2023. Para maiores informações sobre as premiações, consulte o
            Regulamento.
          </Text>
          <Text variant="lpFooterText2">
            Desenvolvido por{' '}
            <Link
              href="https://maquinadepromos.com.br/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Máquina de Promos
            </Link>
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}
