import { Flex, Button, Box, HStack, Link, Icon, Stack } from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import UserProfile from '@components/UserProfile';
import { useRouter } from 'next/router';
import { motion } from 'framer-motion';
import { useSession } from 'next-auth/react';
import { useEffect, useState } from 'react';
import {
  RiArrowRightSFill,
} from 'react-icons/ri';

export default function Header({
  onOpen,
  ...rest
}: {
  onOpen: any;
  rest?: any;
}) {
  const { data: _session, status } = useSession();
  const [isOnTop, setIsOnTop] = useState(false);
  const router = useRouter();

  const handleScroll = () => {
    const scrollPosition = window.scrollY; // => scroll position
    setIsOnTop(scrollPosition == 0);
  };

  useEffect(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <Flex
      position="sticky"
      top="0"
      px={[5, 10, 15, 20]}
      height={status === 'unauthenticated' ? '80px' : '90px'}
      zIndex="1"
      alignItems="center"
      bg={isOnTop ? undefined : 'lpHeaderScrollColor'}
      backdropFilter={isOnTop ? undefined : 'saturate(180%) blur(5px)'}
      opacity={0.95}
      justifyContent="space-between"
      {...rest}
    >
      <Icon
        display={{ base: 'flex', md: 'none' }}
        onClick={onOpen}
        color="white"
        w={6}
        h={6}
        size="lg"
        aria-label="open menu"
        as={MdMenu}
        zIndex={1}
      />

      <HStack zIndex={1} spacing={6}>
        {status === 'unauthenticated' && (
          <Box
            display={{ base: 'none', md: 'flex' }}
            as={motion.div}
            dragConstraints={{ left: -100, right: 100 }}
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition="0.05s linear"
            pr={5}
          >
            <Button
              variant="lpHeaderButton"
              size="lg"
              onClick={() => {
                router.push('/login');
              }}
            >
              Quero participar
            </Button>
          </Box>
        )}
        <Link
          onClick={() => {
            router.push('/regulations');
          }}
          variant="lpHeaderLinks"
        >
          Regulamento
        </Link>

        <Link
          onClick={() => {
            router.push('/faq');
          }}
          variant="lpHeaderLinks"
        >
          Dúvidas?
        </Link>
      </HStack>
      {status !== 'unauthenticated' && (
        <Stack
          direction={['column', 'row']}
          position="absolute"
          marginLeft="auto"
          margin-right="auto"
          left={0}
          right={0}
          zIndex={0}
          spacing={1}
          align={"center"}
          justify={"center"}
        >
          <Button
            rightIcon={<RiArrowRightSFill color="#DC001F" fontSize={'18px'} />}
            color={'black'}
            fontFamily={'Montserrat'}
            fontWeight={700}
            textAlign="center"
            justifyContent="center"
            size={['sm', 'sm']}
            variant={'link'}
            textDecor="none"
            _hover={{ textDecor: 'none' }}
            onClick={() => {
              const scrollElem = document.getElementById('game-banner-day');
              scrollElem?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              });
            }}
          >
            Game do Dia
          </Button>
          <Button
            rightIcon={<RiArrowRightSFill color="#DC001F" fontSize={'18px'} />}
            color={'black'}
            fontFamily={'Montserrat'}
            fontWeight={700}
            textAlign="center"
            justifyContent="center"
            size={['sm', 'sm']}
            variant={'link'}
            textDecor="none"
            _hover={{ textDecor: 'none' }}
            onClick={() => {
              const scrollElem = document.getElementById('game-banner-final');
              scrollElem?.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'nearest',
              });
            }}
          >
            Game Final
          </Button>
        </Stack>
      )}
      <UserProfile />
    </Flex>
  );
}
