import {
  Box,
  Heading,
  Image,
  Text,
  VStack,
  useMediaQuery,
} from '@chakra-ui/react';

type MiddleBannerModuleProps = {
  urlImageMobile: string;
  urlImage: string;
  textTitleLeft: string;
  textTitleRight: string;
  textTitle: string;
  textTitleMobile: string;
};
export default function MiddleBannerModule({
  urlImageMobile,
  urlImage,
  textTitleLeft,
  textTitleRight,
  textTitle,
  textTitleMobile,
}: MiddleBannerModuleProps) {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isLargerThan1900] = useMediaQuery('(min-width: 1900px)');
  if (isMobile) {
    return (
      <VStack>
        <Box p={3} textAlign={'center'}>
          <Heading variant="lpBannerTitle" color="#FF447C">{textTitle}</Heading>
          <Heading mt={5} variant="lpBannerTitle">{textTitleMobile}</Heading>
        </Box>
        <Image
          w={['350', '500', '500', '600', '700', '800']}
          h={['602', '375', '375', '450', '525', '600']}
          src={urlImageMobile}
        />
      </VStack>
    );
  }
  return (
    <VStack justifyContent="center" align="center" p="2%">
      <Box
        position={'absolute'}
        alignSelf={'start'}
        textAlign="start"
        pb={['60', '60', '60', '80', '80', '80']}
        w={['162px', '155px', '170px', '170px', '300px', '500px']}
      >
        <Text variant="lpBannerTitle" color="#FF447C" textAlign="start">
          {textTitle}
        </Text>
        <Text mt={5} variant="lpBannerTitle" textAlign="start">
          {textTitleRight}
        </Text>
      </Box>
      <Box
        position={'absolute'}
        alignSelf={'end'}
        pb={['60', '60', '60', '80', '80', '80']}
        w={['162px', '155px', '170px', '170px', '300px', '500px']}
      >
        <Text variant="lpBannerTitle">{textTitleLeft}</Text>
      </Box>
      <Image
        w={['350', '500', '500', '600', '700', '800']}
        maxWidth={isLargerThan1900 ? '1900' : undefined}
        h={['262', '375', '375', '450', '525', '600']}
        src={urlImage}
      />
    </VStack>
  );
}
