import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  Image,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

type HeadingBannerModuleProps = {
  urlImage: string;
  textTitle: string;
  textDescription: string;
  textButton: string;
  textLoadingButton: string;
  onClick: () => void;
  showButton: boolean;
};
export default function HeadingBannerModule({
  urlImage,
  textTitle,
  textDescription,
  textButton,
  textLoadingButton,
  onClick,
  showButton,
}: HeadingBannerModuleProps) {
  return (
    <Box
      py={[4, 6, 8, 8]}
      color="white"
      pl={{ sm: 'none', md: '5', lg: '8' }}
      pr={{ sm: 'none', md: '5', lg: '8' }}
      bgColor="#FFF8F6"
    >
      <Container
        maxH={{ base: 'full' }}
        maxW={{ base: 'full' }}
        textAlign="center"
      >
        <Stack align={'center'} direction={['column', 'column', 'row']}>
          <VStack
            spacing={[5, 5, 5, 5]}
            direction={['row']}
            justify="center"
            w={'full'}
            h={'full'}
          >
            <Image
              align={'top'}
              w={['350', '500', '500', '600', '700', '800']}
              h={['262', '375', '375', '450', '525', '600']}
              src={urlImage}
            />
          </VStack>
          <VStack
            justifyContent={'space-between'}
            spacing={[5, 5, 5, 5]}
            direction={['row']}
            justify="center"
            w={'full'}
            h={'full'}
          >
            <Heading variant="lpBannerTitle">{textTitle}</Heading>

            <Text variant="lpDescriptionInfos">{textDescription}</Text>
            {showButton && (
              <Center pt={['5', '0']} flexDir={'column'}>
                <Button
                  variant="lpBannerButton"
                  onClick={onClick}
                  //   isLoading={loading}
                  loadingText={textLoadingButton}
                  spinnerPlacement="start"
                >
                  {textButton}
                </Button>
              </Center>
            )}
          </VStack>
        </Stack>
      </Container>
    </Box>
  );
}
